import { Component, AfterViewInit, ElementRef, NgZone, ViewChild, OnInit, HostListener } from '@angular/core';

import { LabelingView } from '../model/labeling-view';
import { CocoProject } from '../model/coco-types';
import { HttpClient } from '@angular/common/http';
import { WasmService } from '../wasm/wasm.service';
import { ToolManagerService } from '../tools/tool-manager.service';

declare var ResizeObserver: any;
// import ResizeObserver from 'resize-observer-polyfill'; //not needed really since > Chrome 64

type EmscriptenModuleDecorator = (module: EmscriptenModule) => void;

@Component({
  selector: 'app-home',
  template: `
    <div class="bg-dark home" role="main">
      <app-toolbar></app-toolbar>
      <div #content class="canvas-container">
        <canvas #canvasElmt oncontextmenu="return false;"></canvas>
      </div>
    </div>
  `,
  styles: [
    `
      .home {
        display: flex;
        height: 100%;
        width: 100%;
        flex-flow: column;
      }
      .canvas-container {
        flex-grow: 1;
        display: flex;
        flex-flow: row;
        justify-content: center;
        position: relative;
      }
      canvas {
        border: 1px solid lightgray;
        position: absolute;
      }
    `,
  ],
})
export class HomeComponent implements AfterViewInit, OnInit {
  private observer: any = null;

  @ViewChild('canvasElmt')
  canvas: ElementRef;
  @ViewChild('content')
  canvasDivElmt: ElementRef;

  constructor(private http: HttpClient, private _wasm: WasmService, private _toolManager: ToolManagerService) {}

  private _controller: any;

  ngOnInit() {}
  ngAfterViewInit() {
    this.observer = new ResizeObserver((entries) => this.resizeCanvas(entries));
    this.observer.observe(this.canvasDivElmt.nativeElement);

    const canvas = this.canvas.nativeElement;
    canvas.id = 'canvas';
    this._wasm.loadModule(canvas).then((success) => {
      if (!success) {
        console.log('Unable to load wasm module');
      }
      this._controller = this._wasm.module.getLabelingController();
      this.http.get('assets/stuff_sample.json', { responseType: 'text' }).subscribe((data) => {
        this._controller.loadProject(data);
      });
    });
  }

  resizeCanvas(entries) {
    const canvas = this.canvas.nativeElement;
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const parent = this.canvasDivElmt.nativeElement;
    canvas.style.height = `calc(100vh - ${vh - parent.clientHeight + 1}px)`;
    canvas.style.width = `calc(100vw - ${vw - parent.clientWidth + 1}px)`;
  }

  ngOnDestroy() {
    this.observer.unobserve(this.canvasDivElmt.nativeElement);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this._toolManager.onKeyDown(event);
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this._toolManager.onKeyUp(event);
  }
}
