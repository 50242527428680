import { Injectable, EventEmitter } from '@angular/core';
import { loadScript } from './utils';

export class ImageBuffer {
  private _bufferPtr = null;
  private _bufferLen = 0;
  private _clampArray: Uint8ClampedArray;

  constructor(width: number, height: number, wasmModule) {
    const m = wasmModule;
    const numBytes = height * width * 4;
    if (this._bufferLen === numBytes) {
      return;
    }

    if (this._bufferPtr) {
      m._free(this._bufferPtr);
    }
    this._bufferPtr = m._malloc(numBytes);
    this._bufferLen = numBytes;
    this._clampArray = m.HEAPU8.subarray(this._bufferPtr, this._bufferPtr + this._bufferLen);
  }

  set(array: Uint8ClampedArray) {
    this._clampArray.set(array);
  }

  copy(array: Uint8ClampedArray) {
    array.set(this._clampArray);
  }

  bufferPointer() {
    return this._bufferPtr;
  }
}

@Injectable({
  providedIn: 'root',
})
export class WasmService {
  private _bufferPtr = null;
  private _bufferLen = 0;

  createRenderingBuffer(width: number, height: number) {
    return new ImageBuffer(width, height, this.module);
  }

  module: any;
  ready: EventEmitter<void> = new EventEmitter();
  constructor() {}

  loadModule(canvasElmt: HTMLCanvasElement): Promise<boolean> {
    return new Promise((accept, reject) => {
      const moduleExportName = 'libyaiat';
      loadScript(moduleExportName, `assets/libyaiat.js`)
        .then(() => {
          const module = <EmscriptenModule>{
            locateFile: (file: string) => {
              return `assets/${file}`;
            },
            canvas: canvasElmt
          };
          return window[moduleExportName](module);
        })
        .then((mod) => {
          this.module = mod;
          accept(true);
        });
    });
  }
}
