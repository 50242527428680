
// this file is automatically generated by git.version.ts script
export class AppBuildInfo {
  branch: string;
  majorVersion: number;
  minorVersion:number;
  buildNumber: number;
  versionString: string;
  timeStamp: number;
  commitId: string;
};

export const appBuildInfo = {
  branch: 'master',
  majorVersion: 1,
  minorVersion: 0,
  buildNumber: 111,
  versionString: '1.0.111',
  timeStamp: 1606329618734,
  commitId: 'ccf3665'
};
