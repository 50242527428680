// @ts-nocheck
import { library, dom } from '@fortawesome/fontawesome-svg-core';

export function registerCustomIcons() {
  const polygonIcon = {
    prefix: 'fac',
    iconName: 'polygon',
    icon: [
      96,
      96,
      [],
      null,
      `M92,33H80.3L59,17.5V8c0-1.7-1.3-3-3-3H44c-1.7,0-3,1.3-3,3v9.5L19.7,33H8c-1.7,0-3,1.3-3,3v12c0,1.6,1.3,3,3,3h4.3l8.5,26  c-1.6,0.1-2.8,1.4-2.8,3v12c0,1.6,1.3,3,3,3h12c1.7,0,3-1.4,3-3v-3h28v3c0,1.6,1.3,3,3,3h12c1.7,0,3-1.4,3-3V80c0-1.6-1.2-2.9-2.8-3
l8.5-26H92c1.7,0,3-1.4,3-3V36C95,34.3,93.7,33,92,33z M91,47h-2h-6.3H81v-6v-4h4.8H91V47z M45,9h10v5.6V19h-4.2h-1.6H45v-4.4V9z M9,47V37h5.2H19v4v6h-1.7H11H9z M32,89v2H22V81h0.1h6.3H32v2V89z M78,81v10H68v-2v-6v-2h3.6H78L78,81z M72.9,77H67
c-1.7,0-3,1.3-3,3v3H36v-3c0-1.7-1.3-3-3-3h-5.9l-8.5-26H20c1.7,0,3-1.4,3-3V38L43.7,23c0.1,0,0.2,0,0.3,0h12c0.1,0,0.2,0,0.3,0 L77,38v10c0,1.6,1.3,3,3,3h1.4L72.9,77z`,
    ],
  };

  library.add(polygonIcon);
  dom.watch();
}
