import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <div id="outer">
      <div>
        <nav class="navbar navbar-expand-lg sticky-top navbar-dark bg-dark  ">
          <a class="navbar-brand" href="#">YAIAT</a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav ml-auto">
              <a class="nav-item nav-link" href="/">Home <span class="sr-only">(current)</span></a>
              <a class="nav-item nav-link" href="/about">About</a>
            </div>
          </div>
        </nav>
      </div>
      <div id="inner_remaining">
        <router-outlet class="bg-dark"></router-outlet>
      </div>
    </div>
  `,
  styles: [
    `
      #outer {
        display: flex;
        flex-flow: column;
        height: 100%;
      }

      #inner_remaining {
        flex-grow: 1;
      }
    `,
  ],
})
export class AppComponent {}
