import { Component } from '@angular/core';
import { ToolManagerService, ToolType } from '../tools/tool-manager.service';

@Component({
  selector: 'app-toolbar',
  template: `
    <div class="btn-toolbar px-1 pb-1" role="toolbar" aria-label="Toolbar">
      <div class="btn-group" btnRadioGroup [(ngModel)]="activeToolName">
        <label class="btn btn-secondary" [btnRadio]="ToolType.MOVE" tabindex="0" role="button"
          ><i class="fas fa-arrows-alt"></i
        ></label>
        <label class="btn btn-secondary" [btnRadio]="ToolType.BRUSH" tabindex="0" role="button"
          ><i class="fas fa-paint-brush"></i
        ></label>
        <label class="btn btn-secondary" [btnRadio]="ToolType.ERASER" tabindex="0" role="button">
          <i class="fas fa-eraser"></i
        ></label>
        <label class="btn btn-secondary" [btnRadio]="ToolType.POLYGON" tabindex="0" role="button">
          <i class="fac fa-polygon"></i
        ></label>
      </div>
    </div>
  `,
  styles: [
    `
      .btn:focus {
        //outline: none;
        //box-shadow: none;
      }
    `,
  ],
})
export class ToolbarComponent {
  ToolType = ToolType;
  activeToolName: ToolType = ToolType.MOVE;

  constructor(private _toolManager: ToolManagerService) {
    _toolManager.onToolChanged.subscribe((activeToolName) => {
      this.activeToolName = activeToolName;
    });
  }
}
