import { Component, OnInit } from '@angular/core';
import { AppBuildInfo, appBuildInfo } from 'src/environments/app-build-info';

@Component({
  selector: 'app-about',
  template: `
    <div class="container">
      <div class="row">
        <div class="col">Version</div>
        <div class="col">{{ appBuildInfo.versionString }}</div>
      </div>
      <div class="row">
        <div class="col">Build Time</div>
        <div class="col">{{getBuildDateTime()}}</div>
      </div>
      <div class="row">
        <div class="col">Commit ID</div>
        <div class="col">{{ appBuildInfo.commitId }} ({{ appBuildInfo.branch }})</div>
      </div>
    </div>
  `,
  styles: [``],
})
export class AboutComponent implements OnInit {
  constructor() {}

  appBuildInfo: AppBuildInfo;

  ngOnInit() {
    this.appBuildInfo = appBuildInfo;
  }

  getBuildDateTime() {
    const myDate = new Date(appBuildInfo.timeStamp);
    return myDate.toLocaleString();
  }
}
